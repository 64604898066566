
export const ROLES = Object.freeze({
    SEEKER : 'Seeker',
    ORGANIZER : 'Organizer',
    ADMIN : 'Admin',
    PLANNER:'Event Planner'
})

export const WEBSITE =
    "https://cultureinyourcity.com/"
    //"https://renloc.vercel.app/"
    // "https://dev.renloc.com/"

export const PUBLIC_ENDPOINTS = ['/auth/signup','/auth/email-verification'
    ,'/auth/login','/auth/forgot-password','/auth/forgot-password/change-password'
    ,'/auth/user-validation']

/***
 * Media BaseUrl
 * @type {string}
 */
export const MEDIA_BASEURL = `${process.env.VUE_APP_API_URL}uploads/`


/***
 * Main Site BaseUrl
 * @type {string}
 */
export const MAIN_SITE_BASEURL = "https://cultureinyourcity.com/"
