<template>
    <form @submit.prevent="loginSubmit">
      
        <api-response v-if="isLocalError" error-type="error" custom-classes="my-2.5" message="Password And Confirm Password Should Be Same"/>
    <api-response v-if="isError" error-type="error" custom-classes="my-2.5" :message="viewError(error.response?.data?.message)"/>
    <api-response v-if="isSuccess" error-type="success" custom-classes="my-2.5" :message="response?.data?.message"/>
      <div class="mb-2.5">
        <p class="mb-2.5">You can change your password now.</p>
        <div v-if="userObj&&userObj?._id"  class="inputWraper w-full">
       <!-- <base-input
       v-if="userObj&&userObj?._id"
       :type="eyeclos?'password':'text'"
          v-model="fieldData.oldpassword"
          required
          is-required
          custom-classes="border border-secondary__color mt-1"
          is-borderless
          label="Old Password"
        /> -->
        <!-- <p v-if="eyeclos" v-on:click="eyetogg"><i class="fa fa-eye-slash"> </i></p><p v-else v-on:click="eyetog"><i class="fa fa-eye"></i></p> -->
        </div>
        <div  class="inputWraper w-full" v-if="userObj&&userObj?._id" >
        <base-input
        :type="eyeclose?'password':'text'"
        v-if="userObj&&userObj?._id" 
          v-model="fieldData.password"
          required
          custom-classes="border border-secondary__color mt-1 mb-2.5"
          is-borderless
          is-required
          label="New Password"
        />
        <p v-if="eyeclose" v-on:click="eyetoggle"><i class="fa fa-eye-slash"> </i></p><p v-else v-on:click="eyetoggl"><i class="fa fa-eye"></i></p>
        </div>
        <div  class="inputWraper w-full" v-else>
          <!-- v-if="userObj&&!userObj?._id" -->
        <base-input
        
        :type="eyeclose?'password':'text'"
          v-model="fieldData.password"
          required
          is-required
          custom-classes="border border-secondary__color mt-1"
          is-borderless
          label="password"
        />
        <p v-if="eyeclose" v-on:click="eyetoggle"><i class="fa fa-eye-slash"> </i></p><p v-else v-on:click="eyetoggl"><i class="fa fa-eye"></i></p>
        </div>
        <div  class="inputWraper w-full">
        <base-input
       
        :type="eyeclosed?'password':'text'"
          v-model="fieldData.confirmPassword"
          required
          is-required
          custom-classes="border border-secondary__color mt-1"
          is-borderless
          label="Confirm Password"
        />
        <p v-if="eyeclosed" v-on:click="eyetoggler"><i class="fa fa-eye-slash"> </i></p><p v-else v-on:click="eyetoggler2"><i class="fa fa-eye"></i></p>
    </div>
      </div>
      <!-- <div  class="inputWraper w-full">
        <base-input
            :type="eyeclosed?'password':'text'"
            v-model="fieldData.password"
            required="true"
            custom-classes="border border-secondary__color mt-1"
            is-borderless
            label="Your Password"
        />
        
       
  <p v-if="eyeclosed" v-on:click="eyetoggler"><i class="fa fa-eye-slash"> </i></p><p v-else v-on:click="eyetoggler2"><i class="fa fa-eye"></i></p>
      </div> -->
      
      <div class="mt-4 flex justify-end">
        <base-button
        is-submit-type
        v-if="!isLoading"
        custom-classes="w-full"
        style="width:100% !important;"
        >
        Change Password
        </base-button>
        <primary-loader :is-loading="isLoading"/>
      </div>
  
    </form>
  </template>
  
  <script setup>
  import BaseInput from "@/components/common/form/base-input";
  import {reactive} from "vue";
  import BaseButton from "@/components/common/buttons/base-button";
  import PrimaryLoader from "@/components/common/loaders/primary-loader";
  import ApiResponse from "@/components/common/text/api-response";
  import { ref } from 'vue'
  import ApiClient from '@/methods/apiclient'
  import useUrlQuery from "@/models/use-url-query"
  import {viewError} from "@/utils/helpers";
  import useToaster from "@/composables/use-toaster";
 
  
import { useRouter } from "vue-router";
 
const router=useRouter()
  const eyeclosed = ref(true)
  const eyeclose = ref(true)
  const eyeclos = ref(true)
  const passTypetoggle = ref('text')
  const passTypetoggl = ref('text')
  const email = useUrlQuery('forget-password')
  const verificationCode = useUrlQuery('code')
  const fieldData = reactive({
    password:"",
    confirmPassword:"",
    email:email,
    verificationCode:verificationCode
  })


  let value = localStorage.getItem("admin");
  let userObj = JSON.parse(value);
  console.log(userObj,"userObj");
  
  const eyetoggler =()=>{
      eyeclosed.value = false
      passTypetoggle.value='password'
    }
  const eyetoggler2 =()=>{
      eyeclosed.value = true
      passTypetoggle.value='text'
    }

    const eyetoggle =()=>{
      eyeclose.value = false
      passTypetoggl.value='password'
    }
  const eyetoggl =()=>{
      eyeclose.value = true
      passTypetoggl.value='text'
    }
    const eyetogg =()=>{
      eyeclos.value = false
      passTypetoggl.value='password'
    }
  const eyetog =()=>{
      eyeclos.value = true
      passTypetoggl.value='text'
    }
  const loginSubmit = (e) => {
    e.preventDefault()

    if(fieldData.password!=fieldData.confirmPassword){
      useToaster("danger","","Confirm password and old password is not matching")
      return 
    }

    if(userObj){
      let data = {
        newPassword:fieldData.password,
        confirmPassword:fieldData.confirmPassword,
        // oldPassword:fieldData.oldpassword,
        email:userObj.email
      }

      ApiClient.put(`profile-settings/password?userId=${userObj._id}`,data).then(res=>{
        console.log("res",res);
        if(res.error){
          useToaster("danger","",viewError(res.message))
        }else{
          useToaster("success","Success",res.message)
          router.push({ path: `/` })
        }
      },)
    }else{
      ApiClient.post('auth/forgot-password/change-password',fieldData).then(res=>{
   
      if(res.error){
          useToaster("danger","",viewError(res.message))
        }else{
          useToaster("success","Success",res.message)
          router.push({ path: `/` })
        }
      })

    }
   
    
 
}
  

  
  </script>
   