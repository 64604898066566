import axios from "@/config/axios-instance";
import TokenService from "./token.service";
import {useMutation} from "vue-query";
import {viewError} from "@/utils/helpers";
import {useRouter} from "vue-router";
import useToaster from "@/composables/use-toaster";
import {ROLES} from "@/utils/constants";
import {ROUTES} from "@/utils/constants/routes";
import {ACCESS_TYPE} from "@/utils/constants/auth";

const useAuthService = () => {
    const router = useRouter()
    //User Log In
    const useHandleLoginInService = (emit) => {
        const handleLogInRequest = (data) => {
            return axios.post(`/auth/login`,data)
        }

        const onSuccess = (response) => {
            TokenService.setUser(response.data.data?.user)
            TokenService.saveLocalRefreshToken(response.data.data?.tokens?.refresh_token)
            TokenService.saveLocalAccessToken(response.data.data?.tokens?.access_token)
            TokenService.setTokenRetries(5)
            useToaster("success","","Login Successfully")
            router.push({name:ROUTES.DASHBOARD})
        }
        const onError = (error) => {
            useToaster("danger","Error",viewError(error.response.data.message))
        }

        return useMutation(
            (loginInData) => handleLogInRequest(loginInData),
            {
                onError,
                onSuccess,
                retry:0
            }
        )

    }

    //User Log Out
    const useHandleLogOutService = () => {
        const onSuccess = (response) => {
            useToaster("success","",response.data.message)
            TokenService.clearStorage()
            router.push({name:ROUTES.HOME})
        }
        const onError = (error) => {
            useToaster("danger","Error",viewError(error.response.data.message))
        }

        const handleLogoutRequest = (userId) => {
            return axios.post(`/auth/logout?userId=${userId.value}`)
        }

        return useMutation(
            (userId)=>handleLogoutRequest(userId),
            {
                onError,
                onSuccess,
                retry:0
            }
        )
    }

    //Forgot Password
    const useHandleForgotPasswordService = () => {

        const onSuccess = (response) =>{
            useToaster("success","Success",response.data.message)
        }
        const onError = (error) => {
            useToaster("danger","Error",viewError(error.response.data.message))
        }

        const handleForgotPasswordRequest = (data) => {
            return axios.post(`/auth/forgot-password`,data)
        }

        return useMutation(
            (forgotPasswordData) => handleForgotPasswordRequest(forgotPasswordData),
            {
                onError,
                onSuccess,
                retry:0
            }
        )

    }

    //Change Password
    const useHandleChangePasswordService = (emit) => {

        const onSuccess = (response) => {
            useToaster("success","Success",response.data.message)
            emit('close-modal')
        }
        const onError = (error) => {
            useToaster("danger","Error",viewError(error.response.data.message))
        }

        const handleChangePasswordRequest = (data) => {
            return axios.post(`/auth/forgot-password/change-password`,data)
        }

        return useMutation(
            (changePasswordData) => handleChangePasswordRequest(changePasswordData),
            {
                onError,
                onSuccess,
                retry:0,
            }
        )
    }

    //Organization Details
    const useHandleOrganizationDetailsService = (emit) => {

        const onSuccess = (response) => {
            useToaster("success","Success",response.data.message)
            emit('open-login')
        }
        const onError = (error) => {
            useToaster("danger","Error",viewError(error.response.data.message))
        }

        const handleOrganizationDetailsRequest = (data,userId) => {
            return axios.post(`/auth/organization?userId=${userId}`,data)
        }

        return useMutation(
            (organizationDetails,userId) => handleOrganizationDetailsRequest(organizationDetails,userId),
            {
                onError,
                onSuccess,
                retry:0,
            }
        )
    }

    //User Email Verification
    const useHandleEmailVerificationService = (emit) => {

        const onSuccess = (response) => {
            useToaster("success","Success",response.data.message)
            emit('open-login')
        }
        const onError = (error) => {
            useToaster("danger","Error",viewError(error.response.data.message))
        }

        const handleEmailVerificationRequest = (data,userId) => {
            return axios.post(`/auth/email-verification?userId=${userId}`,data)
        }

        return useMutation(
            (emailData,userId) => handleEmailVerificationRequest(emailData,userId),
            {
                onError,
                onSuccess,
                retry:0
            }
        )
    }

    // User Sign Up
    const useHandleSignupService = (emit) => {

        const onSuccess = (response) => {
            useToaster("success","Success",response.data.message)
            if(response.data?.data?.user?.accessType === ACCESS_TYPE.INTERNAL){
                emit('open-email-verification',{userId:response.data?.data?.user?._id})
            }else{
                if(response.data?.data?.user?.role === ROLES.ORGANIZER){
                    emit('open-organization-details',{userId:response.data?.data?.user?._id})
                }
                else{
                    emit('open-login')
                }
            }
        }
        const onError = (error) => {
            useToaster("danger","Error",viewError(error.response.data.message))
        }

        const handleSignupRequest = (data) => {
            return axios.post(`/auth/signup`,data)
        }

        return useMutation(
            (signUpData) => handleSignupRequest(signUpData),
            {
                onError,
                onSuccess,
                retry:0,
            }
        )

    }


    return {useHandleLoginInService,useHandleSignupService,useHandleEmailVerificationService,
        useHandleForgotPasswordService, useHandleChangePasswordService,useHandleOrganizationDetailsService,useHandleLogOutService}
};

export default useAuthService;