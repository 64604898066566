import TokenService from "@/services/token.service";
import Cookies from "js-cookie";
import {cookieAuth, cookieRefresh} from "@/utils/constants/cookies";
import {ROUTES} from "@/utils/constants/routes";

const AuthGuard = (to,from,next) => {
    const isUserLoggedIn = !!TokenService.getUser()?._id && (!!Cookies.get(cookieAuth) && !!Cookies.get(cookieRefresh))
    // If the user is authenticated, continue with the route
    if (isUserLoggedIn) {
        return next();
    }

    // Otherwise, redirect to home
    next({name:ROUTES.HOME});
}
export default AuthGuard