<template>
    <form @submit.prevent="loginSubmit">
      <div class="mb-2.5">
        <base-input
          type="email"
          v-model="fieldData.email"
          required="true"
          custom-classes="border border-secondary__color mt-1"
          is-borderless
          is-required
          label="Email Address"
        />
      </div>
      <!-- <div  class="inputWraper w-full">
        <base-input
            :type="eyeclosed?'password':'text'"
            v-model="fieldData.password"
            required="true"
            custom-classes="border border-secondary__color mt-1"
            is-borderless
            label="Your Password"
        />
        
       
  <p v-if="eyeclosed" v-on:click="eyetoggler"><i class="fa fa-eye-slash"> </i></p><p v-else v-on:click="eyetoggler2"><i class="fa fa-eye"></i></p>
      </div> -->
      
      <div class="mt-4 flex justify-end">
        <base-button
            is-submit-type
            v-if="!isLoading"
        >
        Send
        </base-button>
        <primary-loader :is-loading="isLoading"/>
      </div>
  
    </form>
  </template>
  
  <script setup>
  import BaseInput from "@/components/common/form/base-input";
  import {reactive} from "vue";
  import BaseButton from "@/components/common/buttons/base-button";
  import PrimaryLoader from "@/components/common/loaders/primary-loader";

  import { ref } from 'vue'
  import ApiClient from '@/methods/apiclient'
  import useToaster from "@/composables/use-toaster";
  import {viewError} from "@/utils/helpers";
import { useRouter } from "vue-router";

  const router=useRouter()
 
  const eyeclosed = ref(true)
  const passTypetoggle = ref('text')
  const fieldData = reactive({
    email:"",
    role:"admin",
    // accessType : ACCESS_TYPE.INTERNAL,
    // isAdmin:true
  })
  
  const eyetoggler =()=>{
      eyeclosed.value = false
      passTypetoggle.value='password'
    }
  const eyetoggler2 =()=>{
      eyeclosed.value = true
      passTypetoggle.value='text'
    }
  const loginSubmit = (e) => {
    e.preventDefault()
   fieldData.email = fieldData.email.toLowerCase()
    ApiClient.post('auth/forgot-password',fieldData).then(res=>{
  
    if(res.error){
          useToaster("danger","",viewError(res.message))
        }else{
          useToaster("success","Success",res.message)
          router.push({ path: `/` })
        }
    
  })

  }
  
  </script>
  