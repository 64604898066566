
export const ROUTES = Object.freeze({
    HOME : "home",
    DASHBOARD : "dashboard",
    MANUAL_PAYOUT : Object.freeze({
        name:"manualPayout",
        children : {
            PENDING_PAYOUTS : "pendingPayouts",
            PAYOUT_HISTORY : "payoutHistory",
        }
    }),
    USERS_MANAGEMENT : Object.freeze({
        name:"usersManagement",
        children : {
            ALL_USERS : "allUsers",
            SEEKERS : "seekers",
            ORGANIZERS : "organizations",
            CREATE_ORGANIZATION : "createOrganization",
            GUESTS:'guests',
            PLANNER:'planner'
        }
    }),
    MANAGE_BLOGS : Object.freeze({
        name:"manageBlogs",
        children : {
            ALL_BLOGS : "allBlogs",
            CREATE_BLOG : "createBlog",
            EDIT_BLOG:"editBlog"
        }
    }),
    MANAGE_META_TAG: Object.freeze({
        name:"manageMetaTag",
        children:{
            MANAGE_STATIC_META_TAG: Object.freeze({
                name:"manageStaticMetaTag",
                children:{
                    CREATE_STATIC_META_TAG:"createStaticMetaTag",
                    EDIT_STATIC_META_TAG:"editStaticMetaTag"
                }
            }),
            MANAGE_DYNAMIC_META_TAG: Object.freeze({
                name:"manageDynamicMetaTag",
                children:{
                    CREATE_DYNAMIC_META_TAG:"createDynamicMetaTag",
                    EDIT_DYNAMIC_META_TAG:"editDynamicMetaTag"
                }
            })
        }
    }),
    MANAGE_SITE : Object.freeze({
        name:"manageSite",
        children : {
            CULTURE_GROUPS : "cultureGroups",
            CULTURES : "cultures",
            TAGS : "tags",
            CREATE_TAGS : "createTags",
            CREATE_CULTURES_AND_CULTURE_GROUPS : "createCulturesAndCultureGroups",
            CREATE_CULTURES : "createCultures",
            EDIT_BANNERS : "editBanners",
            MANAGE_CULTURE_CONTENT:"manageCultureContent",
            CREATE_COMMISSION:"createCommission",
            MANAGE_COMMISSION:"manageCommission",
            CREATE_PROMO_CODE:"createPromoCode",
            PROMO_CODES:"promoCodes",
            CREATE_EVENT_TYPE:"createEventType",
            EVENT_TYPES:"eventTypes",
            CREATE_BUSINESS_TYPE:"createBusinessType",
            BUSINESS_TYPES:"businessTypes",
            CREATE_CULTURE_CONTENT:"createCultureContent",
            EDIT_CULTURE_CONTENT:"editCultureContent"
        }
    }),
    MANAGE_EVENTS : "manageEvents",
    SETTINGS : "settings",
})
