import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUserSecret,faSearch,faLocationDot,faClose,faUser,faUsers,faComment,faAngleLeft,faCalendar,faClock,faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { faGoogle,faFacebook } from '@fortawesome/free-brands-svg-icons'
import { VueQueryPlugin } from "vue-query";
import VueSidebarMenu from 'vue-sidebar-menu'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import VueGoogleMaps from '@fawmi/vue-google-maps'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import 'vue3-easy-data-table/dist/style.css';
import 'mosha-vue-toastify/dist/style.css'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import "./index.css"
import Paginate from 'vuejs-paginate'
import axiosInterceptors from "@/config/axios-interceptors";

axiosInterceptors()
library.add(faUserSecret,faSearch,faLocationDot,faClose,faGoogle,faFacebook,faUser,faUsers,faComment,faAngleLeft,faCalendar,faClock,faAngleRight)

createApp(App)
    .component('Datepicker',Datepicker)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('easy-data-table', Vue3EasyDataTable)
    .component('paginate', Paginate)
   
    .use(VueSidebarMenu)
    .use(VueQueryPlugin)
    .use(VueGoogleMaps, {
        load: {
            key: process.env.VUE_APP_GOOGLE_MAP_KEY,
            libraries: "places"
        }
    })
    .use(router)
    .mount('#app')