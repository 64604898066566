<template>
    <sidebar-menu :menu="menu" @update:collapsed="onToggleCollapse" @item-click="onItemClick"/>
    <main v-if="route.path !== '/dashboard'" :class="isCollapsed ? 'ml-sidebar__collapsed' : 'ml-sidebar__open'" class="flex items-center justify-center h-screen bg-site__peach px-3">
      <div class="bg-white h-full w-full rounded p-5">
        <router-view></router-view>
      </div>
    </main>
    <main v-else :class="isCollapsed ? 'ml-sidebar__collapsed' : 'ml-sidebar__open'" class="h-screen bg-site__peach">
      <router-view></router-view>
    </main>
</template>

<script setup>

import {reactive, ref, watch} from "vue";
import {ROUTES} from "@/utils/constants/routes";
import {useRoute} from "vue-router"
import useAuthService from "@/services/auth.service";
import TokenService from "@/services/token.service";

const {useHandleLogOutService} = useAuthService()

const {mutate} = useHandleLogOutService()
const userId = ref(TokenService.getUser()?._id)

const isCollapsed = ref(false)
const route = useRoute()

watch(()=>route.path,()=>{
  userId.value = TokenService.getUser()?._id
})

const onItemClick = (e,item) => {
  console.log("e",e)
  // e.classList.add('active navactive')
  console.log("item",item)
  if (item?.title === "Logout"){
    mutate(userId)
  }else{

  }
}
const onToggleCollapse = (collapsed) => {
  isCollapsed.value = collapsed
}
// <i class="fa-light fa-table-columns"></i>
const menu = reactive([
  {
    header: 'Culture In Your City',
    hiddenOnCollapse: true
  },
  {
    href: {name:ROUTES.DASHBOARD},
    title: 'Dashboard',
    
  },
  {
    // href: {name:ROUTES.USERS_MANAGEMENT.name},
    title: 'Users Management',
    href: {name:ROUTES.USERS_MANAGEMENT.children.ALL_USERS},
    child: [
      {
        href: {name:ROUTES.USERS_MANAGEMENT.children.ALL_USERS},
        title: 'All Users',
      },
      {
        href: {name:ROUTES.USERS_MANAGEMENT.children.SEEKERS},
        title: 'Seekers',
      },
      {
        href: {name:ROUTES.USERS_MANAGEMENT.children.ORGANIZERS},
        title: 'Organizations',
      },
      {
        href: {name:ROUTES.USERS_MANAGEMENT.children.PLANNER},
        title: 'Event Planner',
      },
      {
        href: {name:ROUTES.USERS_MANAGEMENT.children.GUESTS},
        title: 'Guests',
      },
      
    ]
  },

  {
    // href: {name:ROUTES.USERS_MANAGEMENT.name},
    title: 'Manage Events',
    href: {name:ROUTES.MANAGE_EVENTS}
  },
  {
    // href: {name:ROUTES.USERS_MANAGEMENT.name},
    title: 'Manage Manual Payout',
    href: {name:ROUTES.MANUAL_PAYOUT.children.PENDING_PAYOUTS},
    child: [
      {
        href: {name:ROUTES.MANUAL_PAYOUT.children.PENDING_PAYOUTS},
        title: 'Pending Payouts',
      },
      {
        href: {name:ROUTES.MANUAL_PAYOUT.children.PAYOUT_HISTORY},
        title: 'Payout History',
      },
    ]
  },
  {
    title:'Manage Culture Group',
    href: {name:ROUTES.MANAGE_SITE.children.CULTURE_GROUPS},
    child:[
        {
          href: {name:ROUTES.MANAGE_SITE.children.CREATE_CULTURES_AND_CULTURE_GROUPS},
          title: 'Create Culture Groups',
        },
        {
          href: {name:ROUTES.MANAGE_SITE.children.CULTURE_GROUPS},
          title: 'All Culture Groups',
        }
    ]
  },
  {
    title:'Manage Culture',
    href: {name:ROUTES.MANAGE_SITE.children.CULTURES},
    child:[
        {
          href: {name:ROUTES.MANAGE_SITE.children.CREATE_CULTURES},
          title: 'Create Cultures',
        },
        {
          href: {name:ROUTES.MANAGE_SITE.children.CULTURES},
          title: 'All Cultures',
        }
    ]
  },
  {
    title:'Manage Event Type',
    href: {name:ROUTES.MANAGE_SITE.children.EVENT_TYPES},
    child:[
        {
          href: {name:ROUTES.MANAGE_SITE.children.CREATE_EVENT_TYPE},
          title: 'Create Event Type',
        },
        {
          href: {name:ROUTES.MANAGE_SITE.children.EVENT_TYPES},
          title: 'All Event Types',
        }
    ]
  },
  {
    title:'Manage Promo Code',
    href: {name:ROUTES.MANAGE_SITE.children.PROMO_CODES},
    child:[
      {
        href: {name:ROUTES.MANAGE_SITE.children.CREATE_PROMO_CODE},
        title: 'Create Promo Code',
      },
      {
        href: {name:ROUTES.MANAGE_SITE.children.PROMO_CODES},
        title: 'All Promo Codes',
      },
    ]
  },
  {
    title:'Manage Commission',
    href: {name:ROUTES.MANAGE_SITE.children.MANAGE_COMMISSION},
    child:[
      {
        href: {name:ROUTES.MANAGE_SITE.children.CREATE_COMMISSION},
        title: 'Create Commission',
      },
      {
        href: {name:ROUTES.MANAGE_SITE.children.MANAGE_COMMISSION},
        title: 'All Commissions ',
      }
    ]
  },
  {
        href: {name:ROUTES.MANAGE_SITE.children.EDIT_BANNERS},
        title: 'Manage Page Banners',
  },
  {
    title:'Manage Culture Content',
    href: {name:ROUTES.MANAGE_SITE.children.MANAGE_CULTURE_CONTENT},
    child:[
      {
        href: {name:ROUTES.MANAGE_SITE.children.CREATE_CULTURE_CONTENT},
        title: 'Create Culture Content',
      },
      {
        href: {name:ROUTES.MANAGE_SITE.children.MANAGE_CULTURE_CONTENT},
        title: 'All Culture Contents',
      }
    ]
  },
  {
    title:'Manage Static Meta Tag',
    href: {name:ROUTES.MANAGE_META_TAG.children.MANAGE_STATIC_META_TAG.name},
    child:[
        {
            href: {name:ROUTES.MANAGE_META_TAG.children.MANAGE_STATIC_META_TAG.children.CREATE_STATIC_META_TAG},
            title: 'Create Static Meta Tag',
        },
        {
            href: {name:ROUTES.MANAGE_META_TAG.children.MANAGE_STATIC_META_TAG.name},
            title: 'All Static Meta Tags',
        }
      
      
    ]
  },
  {
    title:'Manage Dynamic Meta Tag',
    href: {name:ROUTES.MANAGE_META_TAG.children.MANAGE_DYNAMIC_META_TAG.name},
    child:[
        {
            href: {name:ROUTES.MANAGE_META_TAG.children.MANAGE_DYNAMIC_META_TAG.children.CREATE_DYNAMIC_META_TAG},
            title: 'Create Dynamic Meta Tag',
        },
        {
            href: {name:ROUTES.MANAGE_META_TAG.children.MANAGE_DYNAMIC_META_TAG.name},
            title: 'All Dynamic Meta Tags',
        }
      
      
    ]
  },
  {
    title:'Manage Blogs',
    href:{name:ROUTES.MANAGE_BLOGS.children.ALL_BLOGS},
    child:[
        {
            href: {name:ROUTES.MANAGE_BLOGS.children.CREATE_BLOG},
            title: 'Create Blog',
        },
        {
            href: {name:ROUTES.MANAGE_BLOGS.children.ALL_BLOGS},
            title: 'All Blogs',
        }
    ]
  },
  // {
  //   // href: {name:ROUTES.USERS_MANAGEMENT.name},
  //   title: 'Manage Site',
  //   href: {name:ROUTES.MANAGE_SITE.children.CREATE_CULTURES_AND_CULTURE_GROUPS},
  //   child: [
      
  //     // {
  //     //   href: {name:ROUTES.MANAGE_SITE.children.CREATE_CULTURES_AND_CULTURE_GROUPS},
  //     //   title: 'Create Culture Groups',
  //     // },
  //     // {
  //     //   href: {name:ROUTES.MANAGE_SITE.children.CULTURE_GROUPS},
  //     //   title: 'All Culture Groups',
  //     // },
  //     // {
  //     //   href: {name:ROUTES.MANAGE_SITE.children.CREATE_TAGS},
  //     //   title: 'Create Tags',
  //     // },
  //     // {
  //     //   href: {name:ROUTES.MANAGE_SITE.children.TAGS},
  //     //   title: 'All Tags',
  //     // },
      
      
  //     // {
  //     //   href: {name:ROUTES.MANAGE_SITE.children.CREATE_BUSINESS_TYPE},
  //     //   title: 'Create Business Type',
  //     // },
  //     // {
  //     //   href: {name:ROUTES.MANAGE_SITE.children.BUSINESS_TYPES},
  //     //   title: 'Manage Business Type',
  //     // },
  //     // ,
      
  //   ]
  // },
  {
    // href: {name:ROUTES.USERS_MANAGEMENT.name},
    //CHANGEPASSWORD
    title: 'Change Password',
    href: "/changepassword"
  },
  {
    title: 'Logout',
  },
])
</script>
