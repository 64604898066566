import TokenService from "@/services/token.service";
import {cookieAuth, cookieRefresh} from "@/utils/constants/cookies";
import Cookies from "js-cookie";

export const viewError = (error) => {
    return Array.isArray(error) ? error[0] :error
}

export const isUserLoggedIn = () => {
    return !!TokenService.getUser()?._id && (!!Cookies.get(cookieAuth) && !!Cookies.get(cookieRefresh))
}