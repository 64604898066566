import { createRouter, createWebHistory } from 'vue-router'
import LoginView from "@/views/login.view"
import Forgotpassword from "@/views/forgotpassword.view"
import ChangePassword from "@/views/changepassword.view"
import {ROUTES} from "@/utils/constants/routes";
import MainLayout from "@/layouts/main.layout"
import AuthLayout from "@/layouts/auth.layout"
import AuthGuard from "@/router/auth-guard";

const routes = [
  {
    path: '/',
    component: AuthLayout,
    children:[
      {
        path: '',
        name: ROUTES.HOME,
        component: LoginView
      },
      {
        path: 'forgotpassword',
        name: 'Forgotpassword',
        component: Forgotpassword
      },
      {
        path: 'changepassword',
        name: 'ChangePassword',
        component: ChangePassword
      }
    ]
  },
  {
    path: '/',
    component: MainLayout,
    beforeEnter : AuthGuard,
    children:[
      {
        path: 'dashboard',
        name: ROUTES.DASHBOARD,
        component: () => import('../views/dashboard.view.vue')
      },
      {
        path: 'manual-payout',
        name: ROUTES.MANUAL_PAYOUT.name,
        children : [
          {
            path: 'pending-payouts',
            name: ROUTES.MANUAL_PAYOUT.children.PENDING_PAYOUTS,
            component: () => import('@/views/manual-payout/pending-payout.view.vue')
          },
          {
            path: 'payout-history',
            name: ROUTES.MANUAL_PAYOUT.children.PAYOUT_HISTORY,
            component: () => import('@/views/manual-payout/payout-history.view.vue')
          }
        ]
      },
      {
        path: 'users-management',
        name: ROUTES.USERS_MANAGEMENT.name,
        children : [
          {
            path:'all-users',
            name: ROUTES.USERS_MANAGEMENT.children.ALL_USERS,
            component : () => import("@/views/users-management/all-users")
          },
          {
            path:'edit-user/:id',
            name: "Edit User",
            component : () => import("@/views/users-management/edit-user")
          },
          {
            path:'seekers',
            name: ROUTES.USERS_MANAGEMENT.children.SEEKERS,
            component : () => import("@/views/users-management/seekers")
          },
          {
            path:'planner',
            name: ROUTES.USERS_MANAGEMENT.children.PLANNER,
            component : () => import("@/views/users-management/planner")
          },
          {
            path:'organizations',
            name: ROUTES.USERS_MANAGEMENT.children.ORGANIZERS,
            component : () => import("@/views/users-management/organizers")
          },
          {
            path:'add-organization',
            name: ROUTES.USERS_MANAGEMENT.children.CREATE_ORGANIZATION,
            component : () => import("@/views/users-management/add-organization")
          },
          {
            path:'guests',
            name: ROUTES.USERS_MANAGEMENT.children.GUESTS,
            component : () => import("@/views/users-management/guests")
          },
        ]
      },
      {
        path: 'manage-site',
        name: ROUTES.MANAGE_SITE.name,
        children : [
          {
            path:'create-culture-groups',
            name: ROUTES.MANAGE_SITE.children.CREATE_CULTURES_AND_CULTURE_GROUPS,
            component : () => import("@/views/manage-site/create-cultures-and-culture-groups")
          },
          {
            path:'create-cultures',
            name: ROUTES.MANAGE_SITE.children.CREATE_CULTURES,
            component : () => import("@/views/manage-site/create-cultures")
          },
          {
            path:'create-event-type',
            name: ROUTES.MANAGE_SITE.children.CREATE_EVENT_TYPE,
            component : () => import("@/views/manage-site/create-event-type")
          },
          {
            path:'event-types',
            name: ROUTES.MANAGE_SITE.children.EVENT_TYPES,
            component : () => import("@/views/manage-site/event-types")
          },
          
          {
            path:'edit-event-type/:id',
            name: "editEventType",
            component : () => import("@/views/manage-site/edit-event-type")
          },
          {
            path:'create-business-type',
            name: ROUTES.MANAGE_SITE.children.CREATE_BUSINESS_TYPE,
            component : () => import("@/views/manage-site/create-business-type")
          },
          {
            path:'business-types',
            name: ROUTES.MANAGE_SITE.children.BUSINESS_TYPES,
            component : () => import("@/views/manage-site/business-types")
          },
          {
            path:'edit-business-type/:id',
            name: "editBusinessType",
            component : () => import("@/views/manage-site/edit-business-type")
          },
          {
            path:'create-promo-code',
            name: ROUTES.MANAGE_SITE.children.CREATE_PROMO_CODE,
            component : () => import("@/views/manage-site/create-promo-code")
          },
          {
            path:'promo-codes',
            name: ROUTES.MANAGE_SITE.children.PROMO_CODES,
            component : () => import("@/views/manage-site/promo-codes")
          },
          {
            path:'edit-culture-group/:id',
            name: "Edit Culture Group",
            component : () => import("@/views/manage-site/edit-culture-group")
          },
          {
            path:'culture-groups',
            name: ROUTES.MANAGE_SITE.children.CULTURE_GROUPS,
            component : () => import("@/views/manage-site/culture-groups")
          },
          {
            path:'cultures',
            name: ROUTES.MANAGE_SITE.children.CULTURES,
            component : () => import("@/views/manage-site/cultures")
          },
          {
            path:'edit-culture/:id',
            name: "Edit Culture",
            component : () => import("@/views/manage-site/edit-culture")
          },
          {
            path:'create-tags',
            name: ROUTES.MANAGE_SITE.children.CREATE_TAGS,
            component : () => import("@/views/manage-site/create-tags")
          },
          {
            path:'tags',
            name: ROUTES.MANAGE_SITE.children.TAGS,
            component : () => import("@/views/manage-site/tags")
          },
          {
            path:'edit-banners',
            name: ROUTES.MANAGE_SITE.children.EDIT_BANNERS,
            component : () => import("@/views/manage-site/edit-banners")
          },
          {
            path:'create-culture-content',
            name: ROUTES.MANAGE_SITE.children.CREATE_CULTURE_CONTENT,
            component : () => import("@/views/manage-site/create-culture-content")
          },
          {
            path:'edit-culture-content/:id',
            name: ROUTES.MANAGE_SITE.children.EDIT_CULTURE_CONTENT,
            component : () => import("@/views/manage-site/edit-culture-content")
          },
          {
            path:'manage-culture-content',
            name: ROUTES.MANAGE_SITE.children.MANAGE_CULTURE_CONTENT,
            component : () => import("@/views/manage-site/manage-culture-content")
          },
          {
            path:'create-commission',
            name: ROUTES.MANAGE_SITE.children.CREATE_COMMISSION,
            component : () => import("@/views/manage-site/create-commission")
          },
          {
            path:'manage-commission',
            name: ROUTES.MANAGE_SITE.children.MANAGE_COMMISSION,
            component : () => import("@/views/manage-site/manage-commission")
          },
        ]
      },
      {
        path: 'manage-events',
        name: ROUTES.MANAGE_EVENTS,
        component: () => import("@/views/manage-event.view.vue")
      },
      {
        path:'manage-meta-tags',
        name:ROUTES.MANAGE_META_TAG.name,
        children:[
          {
            path: 'manage-static-meta-tag',
            name: ROUTES.MANAGE_META_TAG.children.MANAGE_STATIC_META_TAG.name,
            component : () => import("@/views/manage-meta-tag/manage-static-meta-tag")
          },
          {
            path: 'create-static-meta-tag',
            name: ROUTES.MANAGE_META_TAG.children.MANAGE_STATIC_META_TAG.children.CREATE_STATIC_META_TAG,
            component : () => import("@/views/manage-meta-tag/create-static-meta-tag")
          },
          {
            path: 'edit-static-meta-tag/:id',
            name: ROUTES.MANAGE_META_TAG.children.MANAGE_STATIC_META_TAG.children.EDIT_STATIC_META_TAG,
            component : () => import("@/views/manage-meta-tag/edit-static-meta-tag")
          },
          {
            path: 'manage-dynamic-meta-tag',
            name: ROUTES.MANAGE_META_TAG.children.MANAGE_DYNAMIC_META_TAG.name,
            component : () => import("@/views/manage-meta-tag/manage-dynamic-meta-tag")
          },
          {
            path: 'create-dynamic-meta-tag',
            name: ROUTES.MANAGE_META_TAG.children.MANAGE_DYNAMIC_META_TAG.children.CREATE_DYNAMIC_META_TAG,
            component : () => import("@/views/manage-meta-tag/create-dynamic-meta-tag")
          },
          {
            path: 'edit-dynamic-meta-tag/:id',
            name: ROUTES.MANAGE_META_TAG.children.MANAGE_DYNAMIC_META_TAG.children.EDIT_DYNAMIC_META_TAG,
            component : () => import("@/views/manage-meta-tag/edit-dynamic-meta-tag")
          },
        ]
      },
      {
        path:'manage-blogs',
        name:ROUTES.MANAGE_BLOGS.name,
        children:[
          {
            path: 'all-blogs',
            name: ROUTES.MANAGE_BLOGS.children.ALL_BLOGS,
            component : () => import("@/views/manage-blog/all-blogs.view.vue")
          },
          {
            path: 'create-blog',
            name: ROUTES.MANAGE_BLOGS.children.CREATE_BLOG,
            component : () => import("@/views/manage-blog/create-blog.view.vue")
          },
          {
            path: 'edit-blog/:id',
            name: ROUTES.MANAGE_BLOGS.children.EDIT_BLOG,
            component : () => import("@/views/manage-blog/edit-blog.view.vue")
          },
        ]
      },
      {
        path: 'edit-event/:id',
        name: 'edit-event',
        component: () => import("@/views/edit-event.view.vue")
      }
    ]
  },
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
