<template>
  <form @submit.prevent="loginSubmit">
    <div class="mb-2.5">
      <base-input
        type="email"
        v-model="fieldData.email"
        required="true"
        custom-classes="border border-secondary__color mt-1"
        is-borderless
        label="Email Address"
        is-required
      />
    </div>
    <div  class="inputWraper w-full">
      <base-input
          :type="eyeclosed?'password':'text'"
          v-model="fieldData.password"
          required="true"
          custom-classes="border border-secondary__color mt-1"
          is-borderless
          minlength="8"
          maxlength="30"
          label="Password"
          is-required
      />
         <!-- <base-input
          v-if="!eyeclosed"
          type="text"
          v-model="fieldData.password"
          required="true"
          custom-classes="border border-secondary__color mt-1"
          is-borderless
          label="Your Password"
      /> -->
     
<p v-if="eyeclosed" v-on:click="eyetoggler"><i class="fa fa-eye-slash"> </i></p><p v-else v-on:click="eyetoggler2"><i class="fa fa-eye"></i></p>
    </div>
    <div>
 
  </div>
    <div class="mt-4 flex justify-between">
      <router-link
      to="/forgotpassword"
      class="text-primary text-underline"
    >
     
      Forgot Password
  
    </router-link>
      <base-button
          is-submit-type
          v-if="!isLoading"
      >
        Login
      </base-button>
      <primary-loader :is-loading="isLoading"/>
    </div>
  </form>
</template>

<script setup>
import BaseInput from "@/components/common/form/base-input";
import {reactive} from "vue";
import BaseButton from "@/components/common/buttons/base-button";
import useAuthService from "@/services/auth.service";
import PrimaryLoader from "@/components/common/loaders/primary-loader";
import {ACCESS_TYPE} from "@/utils/constants/auth";
import { ref } from 'vue'
const {useHandleLoginInService} = useAuthService()
const eyeclosed = ref(true)
const passTypetoggle = ref('text')
const {isLoading,mutate} = useHandleLoginInService()
const fieldData = reactive({
  email:"",
  password : "",
  accessType : ACCESS_TYPE.INTERNAL,
  isAdmin:true
})

const eyetoggler =()=>{
    eyeclosed.value = false
    passTypetoggle.value='password'
  }
const eyetoggler2 =()=>{
    eyeclosed.value = true
    passTypetoggle.value='text'
  }
const loginSubmit = () => {
 fieldData.email = fieldData.email.toLowerCase()
  // console.log(fieldData.email.toLowerCase(),'here is my  email ')
  mutate(fieldData)
}
</script>
