import {createToast} from "mosha-vue-toastify";


const useToaster = (type = "success",title,description) => {
    return createToast({title:title, description:description,},
        {
            type:type,
            position:'top-center'
        },
    )
}

export default useToaster
